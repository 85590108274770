import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import Badge from '../Badge';

import { useThemeFlag } from '../../../base/ThemeProvider';

import BaseButton from '../BaseButton';

import styles from './Button.module.scss';

import ButtonBadge from './ButtonBadge';

const cx = classNames.bind(styles);

const Button = forwardRef(
  (
    {
      id,
      alt,
      badge,
      fullWidth,
      color,
      size,
      type,
      dataTest,
      href,
      target,
      children,
      className,
      renderIcon,
      renderTrailingIcon,
      renderContent,
      onClick,
      disabled,
      ...restProps
    },
    ref
  ) => {
    const newButtonsEnabled = useThemeFlag('newButtons');

    const btnClassName = cx(
      'button',
      {
        alt,
        fullWidth,
        disabled: restProps.disabled
      },
      styles[color],
      styles[size],
      className
    );

    if (newButtonsEnabled) {
      let newColor;
      let newSize;

      switch (size) {
        case 'small':
        case 'large':
        case 'medium':
          newSize = size;
          break;
        case 'big':
        case 'big-redesign':
          newSize = 'large';
          break;
        case 'normal':
        default:
          newSize = 'medium';
      }

      switch (color) {
        case 'link':
        case 'tab-link':
        case 'tertiary-light':
        case 'primary':
        case 'tertiary':
        case 'secondary':
          newColor = color;
          break;
        case 'light':
          newColor = 'tertiary';
          break;
        case 'help':
        case 'interaction':
        case 'exposed':
          newColor = 'secondary';
          break;
        case 'select':
        case 'white':
          newColor = 'tertiary';
          break;
        case 'danger':
        case 'complementary':
        case 'filled':
        case 'green-light':
        case 'dark-blue':
        default:
          newColor = 'primary';
      }

      if (alt && newColor !== 'tertiary-light') {
        newColor = 'tertiary';
      }

      return (
        <BaseButton
          id={id}
          onClick={onClick}
          className={className}
          color={newColor}
          size={newSize}
          fullWidth={fullWidth}
          disabled={disabled}
          type={type}
          dataTest={dataTest || restProps['data-test'] || restProps['data-cy']}
          renderIcon={renderIcon}
          renderTrailingIcon={renderTrailingIcon}
          renderContent={renderContent || (() => children)}
          href={href}
          target={target}
          ref={ref}
          roundedCorners={restProps.roundedCorners}
        />
      );
    }

    const commonProps = {
      ...restProps,
      'data-test': dataTest || restProps['data-test'],
      className: btnClassName,
      onClick,
      disabled
    };

    return href ? (
      <a id={id} href={href} ref={ref} {...commonProps}>
        {children}
      </a>
    ) : (
      <button id={id} type="button" ref={ref} {...commonProps}>
        <span>{children}</span>
        {!!badge && (
          <ButtonBadge alt={alt} badge={badge} color={color} size={size} />
        )}
      </button>
    );
  }
);

Button.defaultProps = {
  id: null,
  color: 'primary',
  size: 'medium',
  href: '',
  target: '',
  alt: false,
  badge: '',
  fullWidth: false,
  disabled: false,
  type: 'button',
  onClick: () => {},
  dataTest: null,
  'data-test': null,
  className: '',
  renderIcon: null,
  renderTrailingIcon: null,
  renderContent: null
};

Button.propTypes = {
  color: PropTypes.oneOf([
    // Old colors:
    'primary',
    'secondary',
    'light',
    'danger',
    'help',
    'complementary',
    'filled',
    'select',
    'green-light',
    'white',
    'link',
    'dark-blue',
    'interaction',
    'exposed',
    // New colors:
    'primary',
    'secondary',
    'tertiary',
    'tertiary-light',
    'link',
    'tab-link'
  ]),
  size: PropTypes.oneOf([
    // Old sizes:
    'normal',
    'small',
    'large',
    'big',
    'big-redesign',
    // New sizes:
    'small',
    'medium',
    'large'
  ]),
  id: PropTypes.string,
  alt: PropTypes.bool,
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(Badge.propTypes)
  ]),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top', '']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,
  onClick: PropTypes.func,
  dataTest: PropTypes.string,
  'data-test': PropTypes.string,
  className: PropTypes.string,
  renderIcon: PropTypes.func,
  renderTrailingIcon: PropTypes.func,
  renderContent: PropTypes.func
};

Button.displayName = 'Button';

export default Button;
